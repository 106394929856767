body{
}

.App {
  font-family: sans-serif;
  width: 1140px;
  margin: 0 auto;
}

@media (max-width: 1140px) {
  .App{
    width: 100%;
  }
}



h1 {
  text-align: center;
}

.row {
  display: flex;
  margin: 20px auto;
  max-width: 400px;
  align-content: center;
  justify-content: space-between;
}
